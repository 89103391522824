<template>
  <div class="container">
    <!-- <Top />
    <Menu />
    <router-view class="content"/>
    <Footer /> -->

    <div class="catalog">
      <img src="./assets/catalog/01.jpg" alt="">
      <img src="./assets/catalog/02.jpg" alt="">
      <img src="./assets/catalog/03.jpg" alt="">
      <img src="./assets/catalog/04.jpg" alt="">
      <img src="./assets/catalog/05.jpg" alt="">
      <img src="./assets/catalog/06.jpg" alt="">
      <img src="./assets/catalog/07.jpg" alt="">
      <img src="./assets/catalog/08.jpg" alt="">
      <img src="./assets/catalog/09.jpg" alt="">
      <img src="./assets/catalog/10.jpg" alt="">
      <img src="./assets/catalog/11.jpg" alt="">
      <img src="./assets/catalog/12.jpg" alt="">
    </div>
  </div>
</template>

<script>
// import Main from './components/main.vue'
// import Menu from './components/menu.vue'
// import Top from './components/top.vue'
// import Footer from './components/footer.vue'

export default {
  name: 'App',
  components: {
    // Main,
    // Menu,
    // Top,
    // Footer
  }
}
</script>

<style>
  #app{
    display: flex;
    justify-content: center;
  }
  .container{
    /* max-width: 1200px; */
    width: 100%;
  }
  .content{
    
  }
  .catalog{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .catalog img{
    width: 50%;
  }
  @media screen and (max-width: 720px) {
    .catalog img{
      width: 100%;
    }
  }
</style>
