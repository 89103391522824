import { createWebHistory, createRouter } from 'vue-router';
import Main from '@/components/main.vue'
import Contact from '@/components/contact.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main, // 동적 import
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});