<template>
  <div >
    <div class="part-1">
      <div class="main-img">이미지</div>
      <div class="right-sec">사업분야</div>
    </div>
    <div class="part-2">
      <div>our products</div>
      <div>our service</div>
      <div>news</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .part-1{
    display: flex;
    width: 100%;
  }
  .part-1>div{
    border: 1px solid #000;
    height: 400px;
  }
  .main-img{
    margin-right: 16px;
    width: 100%
  }
  .right-sec{
    width: 300px
  }
  .part-2{
    display: flex;
  }
  .part-2>div{
    border: 1px solid #000;
    margin-right: 16px;
    width: 100%;
    height: 300px;
  }
  .part-2>div:last-child{
    margin-right: 0;
  }
</style>